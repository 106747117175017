import React from "react"
import styled from "styled-components"
import header from "../images/hero-image.jpg"
import DesignTokens from "./designTokens"
import { Container } from "./utils"

const Header = ({ image, children }) => (
  <StyledHeader image={image}>
    <Container>{children}</Container>
  </StyledHeader>
)

export default Header

const StyledHeader = styled.div`
  color: ${DesignTokens.colors.white};
  padding: 9rem ${DesignTokens.spacing[4]} ${DesignTokens.spacing[6]};
  background: ${DesignTokens.colors.primary[500]};
  background-image: url(${props => props.image || header});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  h1 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: ${DesignTokens.fontSizes[6]};
    margin-bottom: ${DesignTokens.spacing[2]};
  }
  h2 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: ${DesignTokens.fontSizes[2]};
    letter-spacing: 0.12rem;
    line-height: 1.3;
  }
  @media screen and (min-width: 769px) {
    padding: 20rem ${DesignTokens.spacing[4]} ${DesignTokens.spacing[9]};
  }
`
