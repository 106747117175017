import React from "react"
import Helmet from "react-helmet"
import Nav from "./nav"
import { IsDesktop, IsMobile } from "./utils"
import LargeNav from "./largeNav"

const Layout = ({ children }) => (
  <div>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins&family=Yantramanav:wght@400;900&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <IsDesktop>
      <LargeNav />
    </IsDesktop>
    <IsMobile>
      <Nav />
    </IsMobile>
    {children}
  </div>
)

export default Layout
