import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import SectorsList from "../components/sectorsList"
import Intro from "../components/intro"
import { Container } from "../components/utils"

const SectorsPage = ({ data }) => (
  <Layout>
    <SEO title={data.page.title} lang="it" />
    <Header image={data.page.heroImage.url}>
      <h1>{data.page.title}</h1>
      <h2>{data.page.subtitle}</h2>
    </Header>
    <Intro title="Settori" />
    <Container>
        <SectorsList sectors={data.page.sectors} />
    </Container>
    <Footer />
  </Layout>
)

export default SectorsPage

export const query = graphql`
  query SectorsQuery {
    page: datoCmsSectorsPage {
      title
      subtitle
      slug
      heroImage {
        url
        alt
      }
      sectors {
          id
        title
        image {
          url
          alt
        }
      }
    }
  }
`
