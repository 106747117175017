import styled from "styled-components"
import DesignTokens from "./designTokens"

const Heading = styled.h1`
  font-size: ${DesignTokens.fontSizes[7]};
  text-transform: ${props => props.downcase ? "none" : "uppercase"};
  font-weight: 900;
  position: relative;
  color: ${ props => props.theme === "dark" ? DesignTokens.colors.white : DesignTokens.colors.primary[500]};
  span {
    color: ${DesignTokens.colors.secondary[500]};
  }
  @media screen and (min-width: 769px) {
    font-size: ${DesignTokens.fontSizes[9]};
    line-height: 1.1;
  }
  ${ props => props.shadow && 
    `&:after {
      content: "${props.children}";
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: ${ props => props.theme === "dark" ? DesignTokens.colors.white : DesignTokens.colors.primary[500]};
      opacity: .2;
      position: absolute;
      top: -30px;
      left: -20px;
      font-size: 55px;
      display: inline-block;
      width: 200%; 
      @media screen and (min-width: 769px) {
        top: -50px;
        left: -30px;
        font-size: 5rem;
      }}`
  }
`

export default Heading
