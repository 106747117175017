import { useStaticQuery, graphql } from "gatsby";

export const useContactMode = () => {
  const contactMode = useStaticQuery(graphql`
    query ContactModeQuery {
      allDatoCmsContactMode {
        nodes {
          additionalInfo
          email
          phone
        }
      }
    }
  `);
  return contactMode.allDatoCmsContactMode.nodes[0];
};
