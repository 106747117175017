import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container } from "./utils"
import Heading from "./heading"

const Intro = ({ title, text, heading, theme }) => (
  <Wrapper theme={theme}>
    <CustomContainer>
      <Heading as={heading} theme={theme} shadow>
        {title}
      </Heading>
      {text && (
        <div dangerouslySetInnerHTML={{ __html: text }} />
      )}
    </CustomContainer>
  </Wrapper>
)
const Wrapper = styled.div`
  background: ${ props => props.theme === "dark" ? DesignTokens.colors.primary[500] : DesignTokens.colors.white};
  padding: 3rem ${DesignTokens.spacing[5]};
  @media screen and (min-width: 769px) {
    padding: 6rem 0 3rem;
  }
  overflow: hidden;
`

const CustomContainer = styled(Container)`
  & > div  {
    margin-top: ${DesignTokens.spacing[5]};
  }
`

export default Intro
